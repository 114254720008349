import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

// bootstrap
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
import "@/styles/custom.scss";

// lazy loading
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
});

// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
library.add({ faWhatsapp, faFacebook, faInstagram })
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
