import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const title = "Ação Camisetas (84) 3221-4142"

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      title: `Página Inicial - ${title}`,
    }
  },
  {
    path: "/sobre",
    name: "about",
    meta: {
      title: `Sobre - ${title}`,
    },
    component: () => import("../views/About.vue")
  },
  {
    path: "/produtos/cursos",
    name: "cursos",
    props: { category: "cursos" },
    meta: {
        title: `Cursos - ${title}`,
    },
    component: () => import("../views/Products.vue")
},
{
    path: "/produtos/fardamento",
    name: "fardamento",
    props: { category: "fardamento" },
    meta: {
        title: `Fardamento - ${title}`,
    },
    component: () => import("../views/Products.vue")
},
{
    path: "/produtos/religioso",
    name: "religioso",
    props: { category: "religioso" },
    meta: {
        title: `Religioso - ${title}`,
    },
    component: () => import("../views/Products.vue")
},
{
    path: "/produtos/abadas",
    name: "abadas",
    props: { category: "abadas" },
    meta: {
        title: `Abadás - ${title}`,
    },
    component: () => import("../views/Products.vue")
},
{
    path: "/produtos/esportivo",
    name: "esportivo",
    props: { category: "esportivo" },
    meta: {
        title: `Esportivo - ${title}`,
    },
    component: () => import("../views/Products.vue")
},
{
    path: "/produtos/outros",
    name: "outros",
    props: { category: "outros" },
    meta: {
        title: `Outros - ${title}`,
    },
    component: () => import("../views/Products.vue")
},
{
    path: "*",
    name: "productNotFound",
    meta: {
        title: `404 Página não encontrada - ${title}`,
    },
    component: () => import("../views/404.vue")
},
  {
    path: "/simulador",
    beforeEnter: () => {
      window.location.href = "https://simulador.acaocamisetas.com.br"
    }
  },
  {
    path: "/carnaval",
    name: "carnaval",
    props: true,
    meta: {
      title: `Abadás - ${title}`,
    },
    component: () => import("../views/Products.vue")
  },
  {
    path: "/servicos",
    name: "services",
    meta: {
      title: `Serviços - ${title}`,
    },
    component: () => import("../views/Service.vue")
  },
  {
    path: "/contato",
    name: "contact",
    meta: {
      title: `Contato - ${title}`,
    },
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/app",
    beforeEnter: () => {
      window.location.href = "https://app.acaocamisetas.com.br"
    }
  },
  {
    path: "*",
    name: "notFound",
    meta: {
      title: `404 Página não encontrada - ${title}`,
    },
    component: () => import("../views/404.vue")
  }
]

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

/* eslint-disable no-unused-vars */
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags)
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el))

  if (!nearestWithMeta) return next()

  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta")

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })

      tag.setAttribute("data-vue-router-controlled", "")

      return tag
    })
    .forEach(tag => document.head.appendChild(tag))

  next()
})
/* eslint-enable no-unused-vars */

export default router
